import React from 'react';
import { createReactEditorJS } from 'react-editor-js';
import { createTools } from 'components/ReactEditor/contstants';
import { normalizedArticles as nzArticles } from 'types/article';

import { HorizontalLine, Label } from '../common/style';

interface Props {
  articles: nzArticles;
  handleInitialize: (instance: any) => void;
  blocks?: any;
}

const ReactEditor: React.FC<Props> = ({ articles, handleInitialize, blocks }) => {
  const tools = createTools(articles);
  const ReactEditorJS = createReactEditorJS();
  return (
    <div style={{ width: '100%' }}>
      <HorizontalLine>
        <Label>
          Editor content
        </Label>
      </HorizontalLine>
      <div style={{ width: '100%' }}>
        <ReactEditorJS
          onInitialize={handleInitialize}
          tools={tools}
          data={blocks}
        />
      </div>
    </div>
  );
};

export default ReactEditor;
