import React from 'react';
import { styled } from '@stitches/react';

const Loader = styled('div', {
  background: 'rgb(251, 251, 251)',
  position: 'absolute',
  display: 'flex',
  top: '0',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 2,
  padding: '8px',
  fontSize: '14px',
  lineHeight: 1,
  border: '1px solid #E0E1E2',
  height: '100%',
  outline: 'none',
  boxSizing: 'border-box',
  zIndex: 99,
  div: {
    boxSizing: 'border-box',
    display: 'block',
    position: 'absolute',
    width: '14px',
    height: '14px',
    border: '2px solid rgb(87, 70, 175)',
    borderRadius: '50%',
    borderColor: '#656565 transparent transparent transparent',
  },
  svg: {
    width: '16px',
    height: '16px',
  },
});

export default () => (
  <Loader>
    <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
          <stop stopColor="#888" stopOpacity="0" offset="0%" />
          <stop stopColor="#888" stopOpacity=".631" offset="63.146%" />
          <stop stopColor="#888" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="3">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.4s"
              repeatCount="indefinite"
            />
          </path>
          <circle fill="#fff" cx="36" cy="18" r="1">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  </Loader>
);
