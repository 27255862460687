import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import { Articles, EditArticle } from '../pages';
import ArticlesArchived from '../pages/Articles/ArticlesArchived';
import CreateArticle from '../pages/Editor/CreateArticle';
import Login from '../pages/Login/Login';
import { Container, Content } from './common/style';
import Header from './Header';

const Routers = () => {
  const { isLoggedIn } = useAuth();

  return (
    <>
      {isLoggedIn ? (
        <Container>
          <Content>
            <Header />
            <Routes>
              <Route path="/" element={<Articles />} />
              <Route path="/archived" element={<ArticlesArchived />} />
              <Route path="/create" element={<CreateArticle />} />
              <Route path="/articles/:id" element={<EditArticle />} />
              <Route path="*" element={<Articles />} />
            </Routes>
          </Content>
        </Container>
      ) : (
        <Container>
          <Content>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </Content>
        </Container>
      )}
    </>
  );
};

export default Routers;
