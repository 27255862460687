import React, {
  ChangeEvent, FC, FormEvent, useEffect, useState,
} from 'react';
import PodcastLinks from 'components/PodcastLinks/PodcastLinks';
import ReactEditor from 'components/ReactEditor/ReactEditor';
import useStore from 'store';

import LoaderForButton from '../../components/common/LoaderForButton';
import {
  Button,
  Fieldset,
  FileButton, FileInput, Form,
  Image, ImageBlock,
  ImageFieldset, Input,
  Label,
  SuccessMessage, TitleAndPicture, TitleBlock,
  VerticalLine,
} from '../../components/common/style';
import Toggle from '../../components/common/Toggle';
import { api, typesOfData } from '../../constants';
import useFetch from '../../hooks/useFetch';
import {
  fullArticle, IPodcastLinks, normalizedArticles as nzArticles, shortArticle, updatedData,
} from '../../types/article';

type EditArticleFormProps = {
  article: fullArticle;
  updateArticle: (articleId: string, articleData: shortArticle) => void;
};

const EditEditorForm: FC<EditArticleFormProps> = ({ article, updateArticle }) => {
  const {
    id, title, slug, meta_title, meta_description, blocks, url, file_id, hidden, podcasts,
  } = article;
  const {
    normalizedArticles,
    getArticles,
    deleteNormalizedArticle,
    addToNormalizedArchivedArticles,
    deleteNormalizedArchivedArticle,
    addToNormalizedArticles,
  } = useStore();

  const mockLinks = {
    anchor: '',
    apple: '',
    google: '',
    spotify: '',
    castbox: '',
    pocketcasts: '',
    radiopublic: '',
  };
  const [articles, setArticles] = useState<nzArticles>(null!);
  const [isPublished, setIsPublished] = useState<boolean>(!hidden);
  const [state, setState] = React.useState({
    title,
    meta_title,
    meta_description,
    hidden: !!hidden,
  });
  const [date] = useState<number>(new Date().getTime());
  const [fileData, setFileData] = React.useState<File | null>(null);
  const [success, setSuccess] = React.useState(false);
  const [editorBlocks] = React.useState<any>(blocks?.props?.blockMap);
  const [imgUrl, setImgUrl] = React.useState<string | null>();
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isImgUpdating, setIsImgUpdating] = React.useState<boolean>(false);
  const [hasPodcasts, setHasPodcasts] = useState<boolean>(podcasts !== null);
  const [podcastLinks, setPodcastLinks] = useState<IPodcastLinks>({ ...mockLinks, ...podcasts });

  const editorCore = React.useRef<any>(null);
  const { loading, request } = useFetch();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formSubmitHandler = async (blocksValue: any, fileId?: string) => {
    setIsUpdating(true);
    const podcastsObj: IPodcastLinks = {};
    Object.keys(podcastLinks).forEach((link) => {
      if (podcastLinks[link as keyof IPodcastLinks]) {
        podcastsObj[link as keyof IPodcastLinks] = podcastLinks[link as keyof IPodcastLinks];
      }
    });
    if (blocksValue) {
      const data: updatedData = {
        ...state,
        podcasts: hasPodcasts ? podcastsObj : null,
        file_id: undefined,
        blocks: blocksValue,
      };
      if (fileId) {
        data.file_id = fileId;
      }

      const result = await request(`${api.url}/articles/${id}`, 'PUT', JSON.stringify(data));
      if (!loading && typeof result !== 'undefined') {
        setSuccess(true);
      }

      updateArticle(id, {
        id, title: state.title, slug, url, hidden: state.hidden,
      });
    }
    setIsUpdating(false);
  };

  const handleInitialize = React.useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (editorCore.current) {
      const savedData = await editorCore.current.save();
      formSubmitHandler({
        props: {
          blockMap: savedData,
        },
      });
    }
  };

  const uploadImage = async () => {
    if (fileData) {
      const fileId = await request(`${api.url}/img`, 'POST', fileData, {
        'Content-Type': fileData.type,
        Authorization: `Basic ${btoa(`${localStorage.getItem('login')}`)}`,
      }, typesOfData.img);
      if (editorCore.current) {
        const savedData = await editorCore.current.save();
        formSubmitHandler({
          props: {
            blockMap: savedData,
          },
        }, fileId.id);
      }
    }
  };

  const togglePublished = () => {
    if (!isPublished === true) {
      showHandler();
    } else {
      hideHandler();
    }
    setIsPublished(!isPublished);
  };

  const hideHandler = async () => {
    deleteNormalizedArticle(id);
    const requestData = JSON.stringify({
      hidden: true,
    });
    await request(`${api.url}/articles/${id}`, 'PUT', requestData);
    addToNormalizedArchivedArticles(id, {
      ...article, hidden: true,
    });
  };
  const showHandler = async () => {
    const requestData = JSON.stringify({
      hidden: false,
    });
    await request(`${api.url}/articles/${id}`, 'PUT', requestData);
    deleteNormalizedArchivedArticle(id);
    addToNormalizedArticles(id, {
      ...article, hidden: false,
    });
  };

  useEffect(() => {
    if (Object.keys(normalizedArticles).length !== 0) {
      setArticles(normalizedArticles);
    } else {
      getArticles().then(() => setArticles(normalizedArticles));
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [success]);

  useEffect(() => {
    if (fileData) {
      setIsImgUpdating(true);
      uploadImage().catch(() => alert('Error uploading photo')).finally(() => setIsImgUpdating(false));
      setImgUrl(URL.createObjectURL(fileData));
    }
  }, [fileData]);
  return (
    <Form onSubmit={handleSave}>
      <div style={{ position: 'absolute', top: -40, right: -1 }}>
        <Button css={{ background: '#fff' }} type="submit">
          Update article
        </Button>
        {isUpdating && <LoaderForButton />}
        {success && <SuccessMessage>Article Updated</SuccessMessage>}
      </div>
      <Button
        css={{
          background: '#fff', position: 'absolute', top: -40, right: 112,
        }}
        onClick={togglePublished}
        type="button"
      >
        Published
        {' '}
        <Toggle isActive={isPublished} setIsActive={setIsPublished} />
      </Button>
      <TitleAndPicture>
        <Fieldset>
          <Label htmlFor="title">
            Title
          </Label>
          <TitleBlock style={{ marginBottom: '20px' }}>
            <Input
              id="title"
              type="text"
              name="title"
              value={state.title}
              onChange={handleInputChange}
              required
            />
            <Input
              id="slug"
              type="text"
              name="slug"
              required
              value={slug}
              readOnly
            />
          </TitleBlock>
          <Fieldset mb20>
            <Label htmlFor="metaTitle">
              Мeta title
            </Label>
            <Input
              id="metaTitle"
              type="text"
              name="meta_title"
              value={state.meta_title}
              onChange={handleInputChange}
              required
            />
          </Fieldset>
          <Fieldset mb20>
            <Label htmlFor="metaDesc">
              Meta description
            </Label>
            <Input
              id="metaDesc"
              type="text"
              name="meta_description"
              value={state.meta_description}
              onChange={handleInputChange}
              required
            />
          </Fieldset>
          <Fieldset mb20 onClick={() => setHasPodcasts(!hasPodcasts)}>
            <Label style={{ display: 'flex', gap: 4 }}>
              Podcasts
              <Toggle isActive={hasPodcasts} setIsActive={setHasPodcasts} />
            </Label>
          </Fieldset>
          {hasPodcasts && <PodcastLinks podcastLinks={podcastLinks} setPodcastLinks={setPodcastLinks} />}
        </Fieldset>
        <ImageFieldset>
          <div>
            <VerticalLine>
              <ImageBlock>
                {fileData && <Image css={{ backgroundImage: `url(${imgUrl})` }} />}
                {file_id && !fileData && (
                <Image
                  css={{ backgroundImage: `url(${api.url}/img/${file_id})` }}
                />
                )}
              </ImageBlock>
              <FileInput
                id="file"
                type="file"
                name="file"
                accept=".jpg, .jpeg"
                onChange={(event) => setFileData(event.target.files![0])}
              />
              <div style={{ position: 'relative' }}>
                <FileButton htmlFor="file">
                  Choose photo
                </FileButton>
                {isImgUpdating && <LoaderForButton />}
              </div>
            </VerticalLine>
          </div>
        </ImageFieldset>
      </TitleAndPicture>
      {editorBlocks && articles && (
        <ReactEditor articles={articles} handleInitialize={handleInitialize} blocks={editorBlocks} />
      )}
    </Form>
  );
};

export default EditEditorForm;
