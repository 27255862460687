import React from 'react';
import { styled } from '@stitches/react';

const Arrow = () => (
  <ArrowIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      color="#494c61"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-right"
    >
      <polyline points="9 18 15 12 9 6" />
    </svg>
  </ArrowIcon>
);

const ArrowIcon = styled('div', {
  paddingRight: '16px',
  height: '24px',
});

export default Arrow;
