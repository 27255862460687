import React, { FC, useEffect } from 'react';
import useStore from 'store';

import { InfoMessage } from '../../components';
import BigLoader from '../../components/common/BigLoader';
import { infoMessageTypes } from '../../constants';
import ArticlesList from './ArticlesList';

const Articles: FC = () => {
  const {
    normalizedArchivedArticlesIds, normalizedArchivedArticles, error, loading, getArchivedArticles,
  } = useStore();
  useEffect(() => {
    if (Object.keys(normalizedArchivedArticlesIds).length === 0) {
      getArchivedArticles();
    }
  }, []);
  return (
    <main>
      <div>
        {
        // eslint-disable-next-line no-nested-ternary
        loading ? <BigLoader />
          : error
            ? <InfoMessage type={infoMessageTypes.error} message={error} />
            : <ArticlesList articlesIds={normalizedArchivedArticlesIds} articles={normalizedArchivedArticles} />
}
      </div>
    </main>
  );
};

export default Articles;
