import { create } from 'zustand';

import { getAllArchivedArticles, getAllArticles, getArticle } from '../api/articles';
import { ArticleById } from '../types/api';
import {
  fullArticle,
  normalizeArticlesData as nzArticlesData,
  normalizedArticles as nzArticles,
  normalizedArticlesIds as nzArticlesIds,
  shortArticle,
} from '../types/article';
import { normalizeArticlesData } from '../utils';

type TArticle = fullArticle | null;

interface State {
  normalizedArticlesIds: nzArticlesIds;
  normalizedArchivedArticlesIds: nzArticlesIds;
  normalizedArticles: nzArticles;
  normalizedArchivedArticles: nzArticles;
  article: TArticle;
  loading: boolean;
  error: string | null;
  getArticles: () => Promise<void>;
  setError: (val: string | null) => Promise<void>;
  getArchivedArticles: () => Promise<void>;
  getArticleById: (id: string) => Promise<void>;
  updateNormalizedArticles: (articleId: string, articleData: shortArticle) => void;
  addToNormalizedArticles: (articleId: string, articleData: shortArticle) => void;
  deleteNormalizedArticle: (articleId: string) => void;
  updateNormalizedArchivedArticles: (articleId: string, articleData: shortArticle) => void;
  addToNormalizedArchivedArticles: (articleId: string, articleData: shortArticle) => void;
  deleteNormalizedArchivedArticle: (articleId: string) => void;
  setNewArticle: (data: ArticleById) => void;
}

const useStore = create<State>((set) => ({
  normalizedArticlesIds: [],
  normalizedArchivedArticlesIds: [],
  normalizedArticles: {},
  normalizedArchivedArticles: {},
  article: null,
  loading: false,
  error: null,

  setError: async (val: string | null) => {
    set({ error: val });
  },

  getArticles: async () => {
    set({ loading: true });
    try {
      const data = await getAllArticles();
      const normilizedData: nzArticlesData = normalizeArticlesData(data);
      set({ normalizedArticlesIds: normilizedData.allIds });
      set({ normalizedArticles: normilizedData.byId });
    } catch (err: any) {
      set({ error: err.message });
    } finally {
      set({ loading: false });
    }
  },

  getArchivedArticles: async () => {
    set({ loading: true });
    try {
      const data = await getAllArchivedArticles();
      const normilizedData: nzArticlesData = normalizeArticlesData(data);
      set({ normalizedArchivedArticlesIds: normilizedData.allIds });
      set({ normalizedArchivedArticles: normilizedData.byId });
    } catch (err: any) {
      set({ error: err.message });
    } finally {
      set({ loading: false });
    }
  },

  getArticleById: async (id: string) => {
    set({ loading: true });

    try {
      const data = await getArticle(id);
      set({ article: data });
    } catch (err: any) {
      set({ error: err.message });
    } finally {
      set({ loading: false });
    }
  },

  updateNormalizedArticles: (articleId: string, articleData: shortArticle) => {
    set((state) => ({
      normalizedArticles: {
        ...state.normalizedArticles, [articleId]: articleData,
      },
    }));
  },

  addToNormalizedArticles: (articleId: string, articleData: shortArticle) => {
    set((state) => ({
      normalizedArticlesIds: [articleId, ...state.normalizedArticlesIds],
      normalizedArticles: {
        [articleId]: articleData,
        ...state.normalizedArticles,
      },
    }));
  },
  deleteNormalizedArticle: (articleId: string) => {
    set((state) => {
      const copy = { ...state.normalizedArticles };
      delete copy[articleId];
      return ({
        normalizedArticles: copy,
        normalizedArticlesIds: state.normalizedArticlesIds.filter((id) => id !== articleId),
      });
    });
  },
  updateNormalizedArchivedArticles: (articleId: string, articleData: shortArticle) => {
    set((state) => ({
      normalizedArchivedArticles: {
        ...state.normalizedArchivedArticles, [articleId]: articleData,
      },
    }));
  },
  addToNormalizedArchivedArticles: (articleId: string, articleData: shortArticle) => {
    set((state) => ({
      normalizedArchivedArticlesIds: [articleId, ...state.normalizedArchivedArticlesIds],
      normalizedArchivedArticles: {
        [articleId]: articleData,
        ...state.normalizedArchivedArticles,
      },
    }));
  },
  deleteNormalizedArchivedArticle: (articleId: string) => {
    set((state) => {
      const copy = { ...state.normalizedArchivedArticles };
      delete copy[articleId];
      return ({
        normalizedArchivedArticles: copy,
        normalizedArchivedArticlesIds: state.normalizedArchivedArticlesIds.filter((id) => id !== articleId),
      });
    });
  },
  setNewArticle: (data: ArticleById) => {
    set({ article: data });
  },
}));

export default useStore;
