import React, {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import ReactEditor from 'components/ReactEditor/ReactEditor';
import useStore from 'store';

import LoaderForButton from '../../components/common/LoaderForButton';
import {
  BackButton,
  Button, Fieldset, FileButton, FileInput,
  Form, Image, ImageBlock, ImageFieldset, Input, Label,
  SmallContainer,
  SuccessMessage,
  TitleAndPicture, TitleBlock, VerticalLine,
} from '../../components/common/style';
import Toggle from '../../components/common/Toggle';
import PodcastLinks from '../../components/PodcastLinks/PodcastLinks';
import { api, typesOfData } from '../../constants';
import useDebounce from '../../hooks/useDebounce';
import useFetch from '../../hooks/useFetch';
import { IPodcastLinks, normalizedArticles as nzArticles } from '../../types/article';

const CreateArticle = () => {
  const initialState = {
    title: '',
    meta_title: '',
    meta_description: '',
    hidden: false,
  };
  const { loading, request } = useFetch();
  const navigate = useNavigate();
  const editorCore = React.useRef<any>(null);
  const [articles, setArticles] = useState<nzArticles>(null!);
  const [state, setState] = useState(initialState);
  const [fileData, setFileData] = useState<File | null>();
  const [success, setSuccess] = useState(false);
  const [slug, setSlug] = useState('');
  const [imgUrl, setImgUrl] = useState<string | null>();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [hasPodcasts, setHasPodcasts] = useState<boolean>(false);
  const [podcastLinks, setPodcastLinks] = useState<IPodcastLinks>({
    anchor: '',
    apple: '',
    google: '',
    spotify: '',
    castbox: '',
    pocketcasts: '',
    radiopublic: '',
  });

  const {
    normalizedArticles, addToNormalizedArticles, setNewArticle, getArticles,
  } = useStore();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (Object.keys(normalizedArticles).length !== 0) {
      setArticles(normalizedArticles);
    } else {
      getArticles().then(() => setArticles(normalizedArticles));
    }
  }, []);

  const getSlug = async () => {
    setSlug('');
    const slugResponse = await request(`${api.url}/transliterate?title=${state.title}`, 'GET');
    if (slugResponse) {
      setSlug(slugResponse);
    }
  };

  const debouncedSearchTerm = useDebounce(state.title, 700);
  const formSubmitHandler = async (blocks: any) => {
    setIsCreating(true);

    if (slug && !(slug.split(/[^a-z-]|\s+/g).length !== 1)) {
      let fileId;
      if (fileData) {
        fileId = await request(`${api.url}/img`, 'POST', fileData, {
          'Content-Type': fileData.type,
          Authorization: `Basic ${btoa(`${localStorage.getItem('login')}`)}`,
        }, typesOfData.img);
      }
      const podcastsObj: IPodcastLinks = {};
      Object.keys(podcastLinks).forEach((link) => {
        if (podcastLinks[link as keyof IPodcastLinks]) {
          podcastsObj[link as keyof IPodcastLinks] = podcastLinks[link as keyof IPodcastLinks];
        }
      });
      const data = JSON.stringify({
        ...state,
        file_id: fileId ? fileId.id : undefined,
        slug,
        podcasts: hasPodcasts ? podcastsObj : undefined,
        blocks: {
          props: {
            blockMap: blocks,
          },
        },
      });
      const result = await request(`${api.url}/articles/create`, 'POST', data);
      if (!loading && typeof result !== 'undefined') {
        setSuccess(true);
        setState(initialState);
        setState((prevState) => ({
          ...prevState,
        }));
        setSlug('');
        setFileData(null);
      }
      if (result) {
        addToNormalizedArticles(result.id, {
          id: result.id,
          title: result.title,
          slug: result.slug,
          url: result.url,
          hidden: result.hidden,
        });
        setNewArticle(result);
        navigate(`/articles/${result.id}`, { state: result.id, replace: true });
      }
    } else {
      alert('Slug error');
    }
    setIsCreating(false);
  };

  useEffect(() => {
    if (state.title !== '') {
      setIsSearching(true);
    } else {
      setSlug('');
    }
  }, [state.title]);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        getSlug().then(() => {
          setIsSearching(false);
        });
      }
    },
    [debouncedSearchTerm],
  );

  useEffect(() => {
    if (fileData) { setImgUrl(URL.createObjectURL(fileData)); }
  }, [fileData]);

  const handleInitialize = React.useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (editorCore.current) {
      const savedData = await editorCore.current.save();
      formSubmitHandler(savedData);
    }
  };

  return (
    <>
      <BackButton>
        <Button css={{ background: '#fff' }} onClick={() => navigate('/')}>Back</Button>
      </BackButton>
      <main className="create">
        <SmallContainer>
          <Form onSubmit={handleSave}>
            <div style={{ position: 'absolute', top: -40, right: -1 }}>
              <Button css={{ background: '#fff' }} type="submit">
                Create article
              </Button>
              {isCreating && <LoaderForButton />}
              {success && <SuccessMessage>Article Created</SuccessMessage>}
            </div>
            <TitleAndPicture>
              <Fieldset>
                <Label htmlFor="title">
                  Title
                </Label>
                <TitleBlock style={{ marginBottom: '20px' }}>
                  <Input
                    id="title"
                    type="text"
                    name="title"
                    required
                    value={state.title}
                    onChange={handleInputChange}
                  />
                  <div style={{ position: 'relative' }}>
                    <Input
                      id="slug"
                      type="text"
                      css={{ width: '100%' }}
                      name="slug"
                      required
                      value={slug}
                      readOnly
                    />
                    {isSearching && <LoaderForButton />}
                  </div>
                </TitleBlock>
                <Fieldset mb20>
                  <Label htmlFor="metaTitle">
                    Meta title
                  </Label>
                  <Input
                    id="metaTitle"
                    type="text"
                    name="meta_title"
                    required
                    value={state.meta_title}
                    onChange={handleInputChange}
                  />
                </Fieldset>
                <Fieldset mb20>
                  <Label htmlFor="metaDesc">
                    Meta description
                  </Label>
                  <Input
                    id="metaDesc"
                    type="text"
                    name="meta_description"
                    required
                    value={state.meta_description}
                    onChange={handleInputChange}
                  />
                </Fieldset>
                <Fieldset mb20 onClick={() => setHasPodcasts(!hasPodcasts)}>
                  <Label style={{ display: 'flex', gap: 4 }}>
                    Podcasts
                    <Toggle isActive={hasPodcasts} setIsActive={setHasPodcasts} />
                  </Label>
                </Fieldset>
                {hasPodcasts && <PodcastLinks podcastLinks={podcastLinks} setPodcastLinks={setPodcastLinks} />}
              </Fieldset>
              <ImageFieldset>
                <div>
                  <VerticalLine>
                    <ImageBlock>
                      {fileData && (
                      <Image style={{ backgroundImage: `url(${imgUrl})` }} />
                      )}
                    </ImageBlock>
                    <FileInput
                      id="file"
                      type="file"
                      name="file"
                      accept=".jpg, .jpeg"
                      onChange={(event) => setFileData(event.target.files![0])}
                    />
                    <FileButton htmlFor="file">
                      Choose photo
                    </FileButton>
                  </VerticalLine>
                </div>
              </ImageFieldset>
            </TitleAndPicture>
            {articles && (
              <ReactEditor articles={articles} handleInitialize={handleInitialize} />
            )}
          </Form>
        </SmallContainer>
      </main>
    </>
  );
};

export default CreateArticle;
