import { styled } from '@stitches/react';

export const Button = styled('button', {
  border: 'solid 1px #e0e1e2',
  gap: '4px',
  cursor: 'pointer',
  display: 'flex',
  padding: '0 12px',
  alignItems: 'center',
  lineHeight: '15px',
  whiteSpace: 'pre',
  borderRadius: '2px',
  justifyContent: 'center',
  backgroundColor: '#fbfbfb',
  height: '32px',
  fontSize: '12px',
  fontWeight: 600,
  color: 'rgb(73, 76, 97)',
  flexDirection: 'row',
  svg: {
    width: '16px',
    height: '16px',
  },
});

export const FileButton = styled('label', {
  border: 'solid 1px #e0e1e2',
  cursor: 'pointer',
  display: 'flex',
  padding: '0 12px',
  alignItems: 'center',
  lineHeight: '15px',
  whiteSpace: 'pre',
  borderRadius: '2px',
  justifyContent: 'center',
  backgroundColor: '#fbfbfb',
  height: '32px',
  fontSize: '12px',
  fontWeight: 600,
  color: 'rgb(73, 76, 97)',
  flexDirection: 'row',
});

export const Input = styled('input', {
  all: 'unset',
  width: 'auto',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 2,
  padding: '8px',
  fontSize: '14px',
  lineHeight: 1,
  border: '1px solid #E0E1E2',
  height: '32px',
  outline: 'none',
  boxSizing: 'border-box',
  media: {
    bp1: 'max-width: 500px',
  },
  '@bp1': {
    width: '40%',
  },
});

export const Markdown = styled('div', {
  width: '100%',
  borderRadius: '5px',
});

export const Fieldset = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  width: '100%',
  variants: {
    mb20: {
      true: { marginBottom: 20 },
    },
  },
});

export const ImageFieldset = styled('fieldset', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 20,
  border: 'none',
  padding: 0,
  margin: '0 0 0 8px',
});

export const Container = styled('div', {
  backgroundColor: '#f8f8f8',
  height: '100%',
  paddingTop: '130px',
  paddingBottom: '130px',
});

export const SmallContainer = styled('div', {
  maxWidth: 600,
  width: '100%',
  margin: '0 auto',
});

export const Content = styled('div', {
  position: 'relative',
  border: 'solid 1px #e0e1e2',
  maxWidth: '600px',
  margin: '0 auto',
  padding: '16px 16px 16px 16px',
  backgroundColor: '#fff',
  minHeight: '600px',
});

export const BackButton = styled('div', {
  position: 'absolute',
  top: -40,
  left: -1,
});

export const TitleBlock = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: '8px',
});

export const ImageBlock = styled('div', {
  width: 128,
  height: 128,
  marginBottom: '8px',
  backgroundColor: '#f5f5f5',
  border: '1px solid #e0e1e2',
  borderRadius: '5px',
});

export const Image = styled('div', {
  width: 126,
  height: 126,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '4px',
});

export const TitleAndPicture = styled('div', {
  display: 'flex',
  width: '100%',
});

export const Label = styled('label', {
  fontSize: 12,
  textTransform: 'uppercase',
});

export const SuccessMessage = styled('div', {
  position: 'absolute',
  top: 6,
  left: -104,
});

export const Icon = styled('div', {
  width: '40px',
  border: '1px solid #E0E1E2',
  height: '40px',
  display: 'flex',
  background: '#F0F0F0',
  alignItems: 'center',
  marginRight: '8px',
  borderRadius: '50%',
  justifyContent: 'center',
});

export const Form = styled('form', {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '24px',
});

export const FileInput = styled('input', {
  opacity: '0.00001',
  position: 'absolute',
  zIndex: '-1',
  width: 1,
  height: 1,
});

export const VerticalLine = styled('div', {
  borderLeft: '1px solid rgb(224, 225, 226)', paddingLeft: '8px',
});

export const HorizontalLine = styled('div', {
  width: '100%', borderBottom: '1px solid #e0e1e2', paddingBottom: '4px',
});
