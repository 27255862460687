import {
  createContext, FC, useContext, useEffect, useMemo,
  useState,
} from 'react';

import BigLoader from '../components/common/BigLoader';
import { Container, Content } from '../components/common/style';
import { api } from '../constants';

const AuthContext = createContext({
  isLoggedIn: false,
  loading: false,
  handleLoggedIn: (value: boolean) => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);

  const loginData = localStorage.getItem('login');

  const handleLoggedIn = (value: boolean) => {
    setLoggedIn(value);
  };

  useEffect(() => {
    if (loginData) {
      const handleFetch = async () => {
        const response = await fetch(`${api.url}/admin-auth`, {
          method: 'GET',
          headers: {
            Authorization: `Basic ${btoa(`${loginData}`)}`,
          },
        });

        if (!response.ok) {
          throw new Error('Something went wrong');
        }

        const data = await response.json();

        if (data.message === 'ok') {
          setLoggedIn(true);
          setLoading(false);
        }
      };

      try {
        handleFetch();
      } catch (error) {
        setLoggedIn(false);
        setLoading(false);
      }
    } else {
      setLoggedIn(false);
      setLoading(false);
    }
  }, [loginData]);

  const contextValue = useMemo(
    () => ({
      isLoggedIn,
      loading,
      handleLoggedIn,
    }),
    [isLoggedIn, loading, handleLoggedIn],
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {loading ? (
        <Container>
          <Content>
            <BigLoader />
          </Content>
        </Container>
      ) : children}
    </AuthContext.Provider>
  );
};
