import { normalizeArticlesData as nzArticlesData, normalizedArticles, shortArticle } from '../types/article';

const normalizeArticlesData = (articles: shortArticle[]): nzArticlesData => {
  const normilezedArticlesById: normalizedArticles = {};

  articles.reduce((obj, currItem) => {
    // eslint-disable-next-line no-param-reassign
    obj[currItem.id] = currItem;
    return obj;
  }, normilezedArticlesById);

  const allIds = articles.map((article) => article.id);

  return {
    byId: normilezedArticlesById,
    allIds,
  };
};

const transformToDateFromTime = (time: number): string => {
  const date = new Date(time);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export { normalizeArticlesData, transformToDateFromTime };
