import {
  ChangeEvent, FC, FormEvent, useState,
} from 'react';
import {
  Button,
  Fieldset, Form, Input, Label,
} from 'components/common/style';

import { api } from '../../constants';
import { useAuth } from '../../context/AuthContext';

const Login: FC = () => {
  const { handleLoggedIn } = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUsername(value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPassword(value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (username && password) {
      setLoading(true);
      try {
        const response = await fetch(`${api.url}/admin-auth`, {
          method: 'GET',
          headers: {
            Authorization: `Basic ${btoa(`${username}:${password}`)}`,
          },
        });

        if (!response.ok) {
          throw new Error('Something went wrong');
        }

        const data = await response.json();

        if (data.message === 'ok') {
          localStorage.setItem('login', `${username}:${password}`);
          setLoading(false);
          handleLoggedIn(true);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h1>Login</h1>
      <Fieldset>
        <Label htmlFor="username">
          Username
        </Label>
        <Input
          id="username"
          type="text"
          name="username"
          required
          value={username}
          onChange={handleUsernameChange}
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor="password">
          Password
        </Label>
        <Input
          id="password"
          type="password"
          name="password"
          required
          value={password}
          onChange={handlePasswordChange}
        />
      </Fieldset>
      <Button type="submit" css={{ background: '#fff', width: '100%' }}>{loading ? 'Loading' : 'Submit'}</Button>
    </Form>
  );
};

export default Login;
