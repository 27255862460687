import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Routers } from './components';
import globalStyle from './components/common/GlobalStyle';
import { AuthProvider } from './context/AuthContext';

const App: FC = () => {
  globalStyle();
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routers />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
