import React, { FC } from 'react';
import { styled } from '@stitches/react';

type InfoMessageProps = {
  type: string;
  message: string;
};

const InfoMessage: FC<InfoMessageProps> = ({ type, message }) => <StyledInfoMessage className={`info-message__${type}`}>{message}</StyledInfoMessage>;

const StyledInfoMessage = styled('p', {
  display: 'inline-block',
  textDecoration: 'underline',
  textDecorationThickness: 5,
  textUnderlinePosition: 'under',
});

export default InfoMessage;
