import React from 'react';

import { typesOfData } from '../constants';

const useFetch = () => {
  const [loading, setLoading] = React.useState(false);

  const request = React.useCallback(
    // eslint-disable-next-line consistent-return
    async (url: string, method?: string, body?: BodyInit, headers?: HeadersInit, type?: string): Promise<any> => {
      setLoading(true);
      let requestHeaders = headers;
      const reqWithHeaders = [
        '/transliterate', '/refresh', '/create', '/getPresignUrl', '/notion-blocks', '/articles',
      ];
      try {
        if (type !== typesOfData.img) {
          if (body || reqWithHeaders.some((substring) => url.includes(substring))) {
            requestHeaders = {
              'Content-Type': 'application/json',
              Authorization: `Basic ${btoa(`${localStorage.getItem('login')}`)}`,
              Origin: 'https://admin.ptrk.fm/',
              'Access-Control-Request-Headers': '*',
              'Access-Control-Request-Method': '*',
            };
          }
        }
        const response = await fetch(`${url}`, {
          method,
          body,
          headers: requestHeaders,
        });
        if (response.status === 400 || response.status === 500) {
          setLoading(false);
          if (!response.ok) {
            throw new Error(await response.text() || 'Something went wrong');
          }
        }
        if (response.status !== 204) {
          const { data } = await response.json();

          if (!response.ok) {
            throw new Error('Something went wrong');
          }
          setLoading(false);

          return data;
        }
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        alert(err.message);
      }
    },
    [],
  );

  return { loading, request };
};

export default useFetch;
