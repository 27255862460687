import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@stitches/react';

import ArchiveIcon from '../assets/icons/ArchiveIcon';
import { Button, SmallContainer } from './common/style';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const checkLocation = location.pathname === '/create' || location.pathname === '/editor';
  const buttonHandler = () => {
    navigate('/create');
  };
  const navigationHandler = () => {
    if (location.pathname === '/') {
      navigate('/archived');
    }
    if (location.pathname === '/archived') {
      navigate('/');
    }
  };
  let checkMainLocation;
  if (location.pathname !== '/create' && !location.pathname.includes('/article')) {
    checkMainLocation = true;
  }

  return (
    <StyledHeader>
      <SmallContainer>
        {checkMainLocation && (
        <HeaderContainer>
          {location.pathname === '/' && <div>Articles</div>}
          {location.pathname === '/archived' && <div>Archived Articles</div>}
          {!checkLocation && (
          <RightButtons>
            <Button onClick={navigationHandler}>
              {location.pathname === '/' ? (
                <>
                  <ArchiveIcon />
                  {' '}
                  Archived
                </>
              ) : 'Back'}
            </Button>
            <Button onClick={buttonHandler}>Create</Button>
          </RightButtons>
          )}
        </HeaderContainer>
        )}
      </SmallContainer>
    </StyledHeader>
  );
};

const HeaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 8,
});

const StyledHeader = styled('header', {
  marginBottom: 8,
});

const RightButtons = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

export default Header;
