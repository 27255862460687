const api = {
  url: 'https://ptrk-fm-worker-rob.lunaxodd.workers.dev',
  dsn: 'https://239c60e2d89043439a57c3c9566685ba@o4504400459333632.ingest.sentry.io/4504400462282752',
  stage: 'Local',
};

const infoMessageTypes = {
  loading: 'loading',
  success: 'success',
  error: 'error',
};

const typesOfData = {
  img: 'img',
};

export { api, infoMessageTypes, typesOfData };
